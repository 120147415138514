
export const getEnvVar = (keyName, defaultVal) => {
    const envVar = process.env[keyName] || process.env[`REACT_APP_${keyName}`];
    return envVar !== undefined ? envVar : defaultVal;
};

export const BUILDING_FOR = getEnvVar("BUILD_FOR","VESTA")?.toUpperCase(); // Values: VESTA, AD, VESTA_INDIA_AZ

export const CONFIGS = {
    VESTA: {
        /* API base (generic for all 'products') */
        API_BASE: "https://checkinn.co/api/v1",
        API_BASE_LOCAL_DEV: "http://cinext.localhost/api/v1",
        /* FBS URL base (For QR Code base URL) */
        URL_FBS: "https://fbs.checkinn.co",
        URL_FBS_LOCAL_DEV: "http://localhost:3000",
        URL_CL: "https://checklists.checkinn.co",
        URL_CL_LOCAL_DEV: "http://localhost:3000",
    },
    VESTA_INDIA_AZ: {
        /* API base (generic for all 'products') */
        API_BASE: "https://platform-in.teamvesta.com/api/v1",
        API_BASE_LOCAL_DEV: "http://cinext.localhost/api/v1",
        /* FBS URL base (For QR Code base URL) */
        URL_FBS: "https://fbs.teamvesta.com",
        URL_FBS_LOCAL_DEV: "http://localhost:3000",
        URL_CL: "https://cl.teamvesta.com",
        URL_CL_LOCAL_DEV: "http://localhost:3000",
    },
    AD: {
        /* API base (generic for all 'products') */
        API_BASE: "https://atom-feedback-system.azurewebsites.net/api/v1",
        API_BASE_LOCAL_DEV: "http://cinext.localhost/api/v1",
        /* FBS URL base (For QR Code base URL) */
        URL_FBS: "https://atom-fbs-app.azurewebsites.net",
        URL_FBS_LOCAL_DEV: "http://localhost:3000",
        URL_CL: "",
        URL_CL_LOCAL_DEV: "http://localhost:3000",
    },
}

export const PROFILE_IMG_FALLBACK = 'https://checkinn.co/app/assets/images/icons/userDefaultIcon.png';

export const isProduction = process.env.NODE_ENV === 'production' ?? true;
export const API_BASE = CONFIGS[BUILDING_FOR][isProduction ? 'API_BASE' : 'API_BASE_LOCAL_DEV'];
export const URL_BASE_FBS = CONFIGS[BUILDING_FOR][isProduction ? 'URL_FBS' : 'URL_FBS_LOCAL_DEV'];

export const COMPANY_BRAND_NAME = "Vesta";

export const getProductMetaObj = (hostname = window.location.hostname) => {
    return {
        product: getEnvVar("PRODUCT", "unknown"),
        productName: getEnvVar("PRODUCT_NAME", "Unknown Product"),
        companyBrandName: getEnvVar("COMPANY_BRAND_NAME", "Vesta"),
    };
}

